import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

const Hero = ({ image, title, content }) => (
  <StyledHero>
    {image && <GatsbyImage alt={title} image={image} />}
    {/* <div>
      <h1>{title}</h1>
      {content && <p>{content}</p>}
    </div> */}
  </StyledHero>
)

const StyledHero = styled.div`
  .gatsby-image-wrapper {
    max-height: 30vh;
  }
`

export default Hero
