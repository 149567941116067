import React from "react";
import classNames from "classnames";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Hero from "../components/hero";
import { Container, Col, Row } from "react-bootstrap";
import styled from "styled-components";

import variables from "../contants/variables";
import Seo from "../components/seo";
import Button from "../components/Button";

const BlogPostTemplate = ({ data, location, path }) => {
  const post = data.contentfulBlogPost;
  const text =
    post.childContentfulBlogPostPreviewTextTextNode?.childMarkdownRemark.html;
  const optionalText =
    post.childContentfulBlogPostOptionalTextTextNode?.childMarkdownRemark.html;
  const previous = data.previous;
  const next = data.next;
  const image = getImage(data.contentfulBlogPost.image);
  const heroimage = post.heroImage?.gatsbyImageData;
  const secondText =
    post.childContentfulBlogPostSecondTextTextNode?.childMarkdownRemark.html;
  const thirdText =
    post.childContentfulBlogPostThirdTextTextNode?.childMarkdownRemark.html;
  const bottomText =
    post.childContentfulBlogPostBottomTextTextNode?.childMarkdownRemark.html;
  const secondImage = getImage(data.contentfulBlogPost.secondImage);
  const pdfFilename = post.pdfFilename;

  return (
    <Layout location={location} path={path}>
      <Seo title={post.title} />
      <div className="img-container">
        <Hero image={heroimage} title={post.title} />
        <div className="img-overlay">
          <h1>{post.title}</h1>
        </div>
      </div>

      {/*Content*/}
      <StyledContainer>
        {/* Top text */}
        <Row className="py-5">
          <Col
            md={12}
            className="my-auto pt-3 pt-md-0"
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        </Row>
        <Row className={classNames({ "d-none": !image && !optionalText })}>
          {/* First image */}
          <Col md={5}>
            <GatsbyImage image={image} alt="first image" />
          </Col>
          {/* Second text */}
          <Col
            md={7}
            className="my-auto pt-3 pt-md-0"
            dangerouslySetInnerHTML={{
              __html: secondText,
            }}
          />
        </Row>
        <Row className={classNames({ "d-none": !image && !optionalText })}>
          {/* Third text */}
          <Col
            md={7}
            className="my-auto pt-3 pt-md-0"
            dangerouslySetInnerHTML={{
              __html: thirdText,
            }}
          />
          {/* Second image */}
          <Col md={5}>
            <GatsbyImage image={secondImage} alt="alt" />
          </Col>
        </Row>
        {bottomText && (
          <Row className="pt-5">
            {/* Bottom text */}
            <Col
              md={12}
              className="my-auto pt-3 pt-md-0"
              dangerouslySetInnerHTML={{
                __html: bottomText,
              }}
            />
          </Row>
        )}
        <Row className="py-5 text-center">
          <a
            href={`${pdfFilename}.pdf`}
            target="_blank"
            rel="noreferrer"
            id="email"
          >
            <Button text="Open as PDF" />
          </a>
        </Row>
        <Row className="py-5">
          {(previous || next) && (
            <nav>
              <ul className="article-nav">
                {previous && (
                  <li>
                    <Link to={`/blog/${previous.slug}`} rel="prev">
                      ← {previous.title}
                    </Link>
                  </li>
                )}
                {next && (
                  <li>
                    <Link to={`/blog/${next.slug}`} rel="next">
                      {next.title} →
                    </Link>
                  </li>
                )}
              </ul>
            </nav>
          )}
        </Row>
      </StyledContainer>
    </Layout>
  );
};

const StyledContainer = styled(Container)`
  h1 {
    margin-bottom: 0;
  }
  h3 {
    margin-bottom: 30px;
  }
  .gatsby-image-wrapper,
  iframe {
    border-radius: 20px;
    box-shadow: ${variables.SHADOWS.lightShadow};
  }
  .article-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    padding: 0;
  }
  .article-nav a {
    border-bottom: 1.5px solid currentColor;
    font-weight: bold;
    color: black;
    -webkit-transition: 0.2s;
  }

  .article-nav a:hover {
    border-bottom-color: transparent;
    color: orange;
  }

  @media (-webkit-min-device-pixel-ratio: 0) {
    .article-nav a {
      background-color: black;
      background-image: -webkit-linear-gradient(
        left,
        orange 0%,
        red 50%,
        transparent 50%
      );
      background-position: 100% 0;
      background-size: 200% 200%;
      color: transparent;
      -webkit-transition: 0.1s 0.2s;
      -webkit-background-clip: text;
    }
    .article-nav a:hover {
      background-position: 0 0;
      color: transparent;
      transition: 0.4s 0;
    }
  }
`;

export default BlogPostTemplate;

export const data = graphql`
  query BlogPostBySlug(
    $slug: String!
    $previousBlogSlug: String
    $nextBlogSlug: String
  ) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      heroImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
      }
      childContentfulBlogPostPreviewTextTextNode {
        childMarkdownRemark {
          html
        }
      }
      image {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
      secondImage {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
      childContentfulBlogPostOptionalTextTextNode {
        childMarkdownRemark {
          html
        }
      }
      childContentfulBlogPostSecondTextTextNode {
        childMarkdownRemark {
          html
        }
      }
      childContentfulBlogPostThirdTextTextNode {
        childMarkdownRemark {
          html
        }
      }
      childContentfulBlogPostBottomTextTextNode {
        childMarkdownRemark {
          html
        }
      }
      pdfFilename
    }
    previous: contentfulBlogPost(slug: { eq: $previousBlogSlug }) {
      slug
      title
    }
    next: contentfulBlogPost(slug: { eq: $nextBlogSlug }) {
      slug
      title
    }
  }
`;
